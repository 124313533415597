
import { mapGetters } from 'vuex'

import CheckoutHeaderNav from './partials/CheckoutHeaderNav'
import CheckoutFooterNav from './partials/CheckoutFooterNav'
import CallbackModal from '@/components/modals/CallbackModal'
import EmailModal from '@/components/modals/EmailModal'
import InfoModal from '@/components/modals/InfoModal'

export default {
  components: {
    CheckoutHeaderNav,
    CheckoutFooterNav,
    CallbackModal,
    EmailModal,
    InfoModal
  },

  computed: {
    ...mapGetters({
      info: 'modal/info',
      callback: 'modal/callback',
      email: 'modal/email'
    })
  },

  mounted () {
    const html = document.documentElement
    html.style.overflow = null
    this.$store.dispatch('cart/setCartModalActive', false)
  },

  methods: {
    toggleCallbackModal () {
      this.$store.dispatch('modal/setCallbackActive', false)
    },

    toggleEmailModal () {
      this.$store.dispatch('modal/setEmailActive', false)
      this.$store.dispatch('modal/setEmailTab', null)
    },

    toggleInfoModal () {
      // this.$store.dispatch('modal/setInfoModalActive', !this.info.active)
      this.$store.dispatch('modal/setInfoModalActive', false)
      this.$store.dispatch('modal/setInfoModalTitle', '')
      this.$store.dispatch('modal/setInfoModalDescription', '')
      this.$store.dispatch('modal/setInfoModalPage', { slug: '' })
    }
  }
}
