
import { mapGetters } from 'vuex'

import Notification from './partials/Notification'
import HeaderNavTop from './partials/HeaderNavTop'
import HeaderNavBottom from './partials/HeaderNavBottom'
import FooterNav from './partials/FooterNav'
import Cookie from '@/components/Cookie'
import CartModal from '@/components/modals/CartModal'
import NotifyAboutAvailabilityModal from '@/components/modals/NotifyAboutAvailabilityModal'
import PreorderModal from '@/components/modals/PreorderModal'
import CallbackModal from '@/components/modals/CallbackModal'
import EmailModal from '@/components/modals/EmailModal'
import InfoModal from '@/components/modals/InfoModal'

import referrer from '@/mixins/referrer.js'

export default {
  components: {
    Notification,
    HeaderNavTop,
    HeaderNavBottom,
    FooterNav,
    Cookie,
    CartModal,
    NotifyAboutAvailabilityModal,
    PreorderModal,
    CallbackModal,
    EmailModal,
    InfoModal
  },

  mixins: [
    referrer
  ],

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      cartModal: 'cart/modal',
      info: 'modal/info',
      preorder: 'modal/preorder',
      notifyAboutProductAvailability: 'modal/notifyAboutProductAvailability',
      callback: 'modal/callback',
      email: 'modal/email',
      notification: 'notification',
      schemaOrg: 'schema.org/schemaOrg'
    })
  },

  mounted () {
    const html = document.documentElement
    html.style.overflow = null
    this.setReferrer()
  },

  methods: {
    toggleCartModal () {
      this.$store.dispatch('cart/setCartModalActive', !this.cartModal.active)
    },

    togglePreorderModal () {
      this.$store.dispatch('modal/togglePreorderModal', !this.preorder.active)
      this.$store.dispatch('modal/setPreorderId', null)
      this.$store.dispatch('modal/setPreorderTitle', '')
      this.$store.dispatch('modal/setPreorderImage', {
        lazy: '',
        src: '',
        alt: ''
      })
    },

    toggleNotifyAboutAvailabilityModal () {
      this.$store.dispatch('modal/toggleNotifyAboutProductAvailabilityModal', !this.notifyAboutProductAvailability.active)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityId', null)
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityTitle', '')
      this.$store.dispatch('modal/setNotifyAboutProductAvailabilityImage', {
        lazy: '',
        src: '',
        alt: ''
      })
    },

    toggleCallbackModal () {
      this.$store.dispatch('modal/setCallbackActive', false)
    },

    toggleEmailModal () {
      this.$store.dispatch('modal/setEmailActive', false)
      this.$store.dispatch('modal/setEmailTab', null)
    },

    toggleInfoModal () {
      this.$store.dispatch('modal/setInfoModalActive', !this.info.active)
      this.$store.dispatch('modal/setInfoModalTitle', '')
      this.$store.dispatch('modal/setInfoModalDescription', '')
      this.$store.dispatch('modal/setInfoModalPage', { slug: '' })
    }
  },

  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    i18nHead.link = i18nHead.link.filter(e => e.rel !== 'canonical')

    i18nHead.script = [
      {
        type: 'application/ld+json',
        json: this.schemaOrg.store
      }
    ]

    return i18nHead
  }
}
