
import { mapGetters } from 'vuex'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [
    clickaway
  ],

  data () {
    return {
      checkoutFooterMarginPath: [
        'checkout'
      ],
      langswitcher: {
        active: false
      }
    }
  },

  computed: {
    ...mapGetters({
      cart: 'cart/cart',
      store: 'store'
    }),

    years () {
      const years = {
        established: this.$moment(this.store.established_on).year(),
        current: this.$moment().year()
      }

      if (years.established < years.current) {
        return `${years.established}–${years.current}`
      } else {
        return years.current
      }
    },

    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  },

  methods: {
    hideLangswitcher () {
      this.langswitcher.active = false
    }
  }
}
