
export default {
  props: {
    error: {
      required: true,
      type: Object
    }
  },

  computed: {
    message () {
      if (this.error.statusCode === 404) {
        return this.$t('pages.error.404')
      } else if (this.error.statusCode === 403) {
        return this.$t('pages.error.403')
      } else {
        return this.$t('pages.error.other')
      }
    }
  },

  mounted () {
    const html = document.documentElement
    html.style.overflow = null
  },

  methods: {
    reload () {
      window.location.reload()
    }
  },

  head () {
    return {
      title: `${this.error.statusCode} ${this.message}`,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: `${this.error.statusCode} ${this.message}`
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.appUrl + this.$route.path
        },
        {
          hid: 'robots',
          name: 'robots',
          content: 'noindex'
        }
      ]
    }
  }
}
